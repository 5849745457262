<template>
    <div class="evaluate">
        <div class="evaluate-item" v-for="(item, index) in goodsData">
            <div class="item-header">
                <div class="evaluate-info">
                    <p><span class="span-right">评论人:</span>{{ item.user_name }}</p>
                    <P class="order-number">订单编号：{{ item.order_no }}</P>
                    <P class="evaluateStart" ><span class="span-right">评价:</span>
                        <img class="start1" v-for="item in item.grade" src="@/assets/images/student/up_start.png" alt="">
                        <img class="start" v-for="item in (5 - item.grade)" src="@/assets/images/student/down_start.png"
                            alt="">
                    </P>
                </div>
                <div class="button" @click="change(item)">
                    {{ (!item.stu_answer.content&& !item.isReview)  ? '回复' : item.isReview ? "收起" : '编辑' }}
                    <!-- <span v-show="!item.stu_answer.content && !item.isReview">回复</span>
                    <span v-show="item.stu_answer.content && item.isReview">收起</span>
                    <span v-show="item.stu_answer.content && !item.isReview">编辑</span> -->
                </div>

            </div>
            <div class="item-content">
                <div class="goodsinfo">
                    <img class="goods-img" :src=item.goods_info.good_images alt="">
                    <div class="goods-name">
                        <p class="good-name">商品名称：{{ item.goods_info.good_title }}</p>
                        <p>SKU编码：{{ item.goods_info.good_sku }}</p>
                        <p>销售价：{{ item.goods_info.sales_price }}</p>
                    </div>
                </div>
                <div class="review">
                    <div class="noData" v-if="!item.content && !item.image.length">
                        <p class="no-review">此用户未填写评价内容</p>
                        <p class="user-name">{{ item.user_name }} 0条回复</p>
                    </div>
                    <div class="hasData" v-else>
                        <p class="has-content">{{ item.content }}</p>
                        <div class="goodes-img">
                            <div v-for="itemimg in item.image" >                             
                                <div class="show-img" v-if="itemimg" @click="preview(itemimg)">
                                     <img :src=itemimg alt="">
                                </div>
                            </div>
                        </div>
                        <!-- <p>
                            {{ item.user_name }}
                        </p> -->
                    </div>
                    <!-- 学生回复和展示 -->
                    <div class="input-bk" v-if="item.isReview">
                        <div class="input-box">
                            <el-input class="send-message-input" v-model="item.sendMessage" type="textarea"
                                resize="none" :placeholder="`[回复${item.user_name}]`" maxlength="400" ></el-input>
                        </div>
                        <div class="send-bottom">
                            <el-button class="send-button" size="small"
                                @click="sendInputMessage(item.id, index)">回复</el-button>
                        </div>
                    </div>
                    <!-- 展示 -->
                    <div class="show-view" v-if="!item.isReview && item.stu_answer.content">
                        <p class="review-num"></p>
                        <div>
                            <div class="merchant_review">
                                <span>[回复{{ item.user_name }}]:</span>
                                <p>{{ item.stu_answer.content }}</p>
                            </div>
                            <p class="review_item_date">{{ item.stu_answer.time }}  {{ item.stu_answer.username }}</p>
                        </div>

                    </div>


                </div>
            </div>

        </div>
       <div class="pagination">
        <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="pageform.page"
      :page-size="pageform.limit"
      layout="prev, pager, next, jumper"
      :total="total">
    </el-pagination>
       </div>
       <!-- 预览图片弹框 -->
       <el-dialog :visible.sync="imgVisible" width="40%" :before-close="closeDialog">
            <div class="big-img">
                <img :src="imgUrl" alt="">
            </div>
        </el-dialog>

    </div>
</template>

<script>
import { stuOpGetOrderComment, stuOpSaveOrderComment } from "@/utils/apis.js"
export default {
    data() {
        return {
            merchant_name: '',
            goodsData: [],
            pageform: {
                page: 1,
                limit: 3
            },
            total: 0,
            imgUrl:'',
            imgVisible:false

        }
    },
    methods: {
        sendInputMessage(id, ind) {
            let param = {
                id: id,
                content: this.goodsData[ind].sendMessage
            }
            stuOpSaveOrderComment(param).then(res => {
                if (res.code == 200) {
                    this.goodsData.forEach((item, index) => {
                        if (index == ind) {
                            this.$set(item, 'isReview', false)
                        }
                    })
                    this.GetOrderComment()
                }
            })

        },
        getNowDate() {
            let time = new Date()
            let y = time.getFullYear()
            let mo = time.getMonth() + 1
            mo = mo < 9 ? '0' + mo : mo
            let d = time.getDate()
            d = d < 9 ? '0' + d : d
            let h = time.getHours()
            h = h < 9 ? '0' + h : h
            let mi = time.getMinutes()
            mi = mi < 9 ? '0' + mi : mi
            let s = time.getSeconds()
            s = s < 9 ? '0' + s : s
            let str = `${y}-${mo}-${d} ${h}:${mi}:${s}`
            return str
        },
        GetOrderComment() {
            stuOpGetOrderComment(this.pageform).then(res => {
                this.goodsData = res.data.list
                this.total=res.data.total
                this.goodsData.forEach(product => {
                    // 添加一个新属性
                    this.$set(product, "isReview", false)
                    this.$set(product,'sendMessage','')
                });
                
            })
        },
        change(item) {
            item.sendMessage=item.stu_answer.content
            this.goodsData.forEach((data, index) => {
                if (data.goods_id == item.goods_id) {
                    // this.$set(item, 'isReview', !item.isReview)
                        data.isReview=!data.isReview
                }
            })
           
        },
        handleCurrentChange(val){
            this.pageform.page=val
            this.GetOrderComment()

        },
        // 点击放大图片预览
        preview(url){
            this.imgVisible=true
            this.imgUrl=url
        },
        closeDialog(){
            this.imgVisible=false
            this.imgUrl=''
        
        },
        

    },
    mounted() {
        this.GetOrderComment()
    }

}
</script>

<style lang="scss" scoped>
.evaluate {
    padding: 0 20px;
    padding-bottom: 120px;
    box-sizing: border-box;
    overflow-y: auto;
    height: 100vh;

    .evaluate-item {
        width: 100%;

        border-radius: 4px;
        border-bottom: 1px solid #E3E2E5;
        margin-top: 20px;

        .item-header {
            height: 60px;
            background-color: #F2F2F6;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
            box-sizing: border-box;

            .button {
                color: #317BE9;
                cursor: pointer;
            }

            .evaluate-info {
                display: flex;

                .order-number {
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }
        }

        .item-content {
            width: 100%;
            height: 100%;
            display: flex;

            .goodsinfo {
                width: 600px;
                // background-color: pink;
                border-right: 1px solid #D9D9D9;
                padding: 20px;
                box-sizing: border-box;
                display: flex;
                align-items: flex-start;

                .goods-img {
                    width: 80px;
                    height: 80px;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover; 
                    }
                   
                }

                .goods-name {
                    margin-left: 30px;
                    box-sizing: border-box;
                    width: 100%;
                    p {
                        margin: 0;
                        padding: 0px !important;
                        font-size: 14px;
                        color: #0B0B0B;
                        font-weight: medium;
                    }
                    .good-name{
                        width: 90%;
                        white-space: nowrap; /* 文本在一行显示 */
                        overflow: hidden; /* 超出部分隐藏 */
                        text-overflow: ellipsis; /* 显示省略号 */
                    }
                }
            }

            .review {
                flex: 1;
                padding: 20px 0;
                margin-left: 20px;
                font-size: 14px;
                box-sizing: border-box;

                .noData {

                    p {
                        margin: 0;
                        padding: 0px !important;
                    }

                    .no-review {
                        color: #999999;
                    }

                    .user-name {
                        color: #35323B;
                    }
                }

                .hasData {
                    .has-content{
                        padding: 0;
                        margin: 0;
                        color: #0B0B0B;
                        font-weight: medium;
                    }
                    .goodes-img {
                        display: flex;
                        margin-bottom: 20px;
                        margin-top: 10px;

                        .show-img {
                            width: 100px;
                            height: 100px;
                            border-radius: 4px;
                            margin-right: 10px;
                            img{
                                width: 100%; /* 图片宽度占满容器 */
                                height: 100%; /* 图片高度占满容器 */
                                object-fit: cover; /* 图片不变形，占满整个盒子 */
                                border-radius: 4px;
                            }
                           
                        }

                    }


                }

            }
        }
    }
}

.input-bk {
    background-color: #F5F6F8;
    height: 150px;
    padding: 20px;
    box-sizing: border-box;

    .send-bottom {
        display: flex;
        margin-top: 10px;
        justify-content: flex-end;

        .send-button {
            background-color: #1AB163;
            color: #FFFFFF;
            border-radius: 6px;

        }
    }

}

.show-view {
    color: #666666;
    font-size: 14px;

    .review-num {
        text-align: center;
        border-bottom: 1px solid #D9D9D9;
        padding-bottom: 10px;
    }

    div {
        .merchant_review {
            display: flex;
            align-items: flex-start;

            span {
                margin-right: 10px;
                white-space: nowrap;
            }

            p {
                padding: 0;
                margin: 0;
            }
        }

        .review_item_date {
            color: #999999;
            font-size: 14px;
        }
    }
}
.big-img{
    width: 100%;
    height: 500px;
    // background-color: pink;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

::v-deep .el-textarea__inner {
    height: 70px;
}
.evaluateStart{
    display: flex;
    align-items: center;
    color: #35323B;
    font-size: 14px;
}
.span-right{
    margin-right: 5px;
}

.start1 {
    width: 20px;
    height: 20px;
}

.start {
    width: 20px;
    height: 20px;
}
.pagination{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
</style>